<template>
    <v-card flat>
        <v-card-title>
            <span v-if="currentSpaceType === spaceTypes.EDUCATION_SPACE" class="secondary--text font-weight-bold">Course Configuration</span>
            <span v-else-if="currentSpaceType === spaceTypes.RESEARCH_SPACE" class="secondary--text font-weight-bold">Project Configuration</span>
            <span v-else-if="currentSpaceType === spaceTypes.VENDOR_SPACE" class="secondary--text font-weight-bold">Dataset Configuration</span>
        </v-card-title>
        <v-card-text>
            <v-tabs v-model="tab">
                <v-tab v-if="isSpaceAdmin">General Settings</v-tab>
                <v-tab>Secrets</v-tab>
                <v-tab>Large file storage</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item v-if="isSpaceAdmin">
                    <v-card flat>
                        <v-card-title>
                            <span class="secondary--text font-weight-bold">General settings</span>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <h3>Integrations</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" lg="4" xl="3">
                                    <v-card flat elevation="3" class="ma-1">
                                        <v-list three-line color="transparent">
                                            <v-list-item>
                                                <v-list-item-avatar>
                                                    <v-icon class="primary lighten-1" dark>{{ isDropboxLinked ? 'check_circle' : 'circle' }}</v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title>Dropbox</v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        Each member of a space needs to enable Dropbox synchronization separately for their own account.
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                        <v-card-actions>
                                            <v-spacer />
                                            <v-btn text color="primary" v-if="!isDropboxLinked" :to="{ name: 'user-dropbox' }">Enable</v-btn>
                                            <v-btn text color="secondary" disabled v-else>Enabled</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <template v-for="(name, id) in featureGroups">
                                <v-row class="mt-4" :key="id">
                                    <v-col>
                                        <h3>{{ name }}</h3>
                                    </v-col>
                                </v-row>
                                <v-row :key="name">
                                    <v-col cols="12" sm="6" lg="4" xl="3" v-for="(feature, index) in featuresByGroup(id)" :key="index">
                                        <org-feature-status :feature="feature" :enableOnly="true" level="space" />
                                    </v-col>
                                </v-row>
                            </template>
                            <v-row>
                                <v-col>
                                    <h3>Resource mapping</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="4" xl="3">
                                    <v-card flat elevation="3" class="ma-1">
                                        <v-list three-line color="transparent">
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Credit-based services</v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        All credit-based services are drawing upon this resource pool when they are utilized in this space.
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                        <v-card-text class="py-0">
                                            <v-select
                                                required
                                                :items="managerResourcePools"
                                                :disabled="fetchingAvailableResourcePools"
                                                :loading="fetchingAvailableResourcePools"
                                                v-model="resourcePoolIdCredit"
                                                item-text="long_id"
                                                item-value="rpid"
                                                label="Resource pool"
                                                outlined
                                                dense
                                                hide-details></v-select>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer />
                                            <v-btn
                                                v-if="managerResourcePools.length > 0"
                                                color="primary"
                                                text
                                                :disabled="loading || fetchingAvailableResourcePools"
                                                :loading="loading || fetchingAvailableResourcePools"
                                                @click="updateResourcePoolForCredit">
                                                save
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" xl="3">
                                    <v-card flat elevation="3" class="ma-1">
                                        <v-list three-line color="transparent">
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Plan-based services</v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        All plan-based services are drawing upon this resource pool when they are utilized in this space.
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                        <v-card-text class="py-0">
                                            <v-select
                                                required
                                                :items="managerResourcePools"
                                                :disabled="fetchingAvailableResourcePools"
                                                :loading="fetchingAvailableResourcePools"
                                                v-model="resourcePoolIdSubscription"
                                                item-text="long_id"
                                                item-value="rpid"
                                                label="Resource pool"
                                                outlined
                                                dense
                                                hide-details></v-select>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer />
                                            <v-btn
                                                v-if="managerResourcePools.length > 0"
                                                color="primary"
                                                text
                                                :disabled="loading || fetchingAvailableResourcePools"
                                                :loading="loading || fetchingAvailableResourcePools"
                                                @click="updateResourcePoolForSubscription">
                                                save
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" xl="3">
                                    <v-card flat elevation="3" class="ma-1">
                                        <v-list three-line color="transparent">
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Large File Storage visibility</v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        Only LFS objects in this resource pool are visible and can be attached to this space.
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                        <v-card-text class="py-0">
                                            <v-select
                                                required
                                                :items="managerResourcePools"
                                                :disabled="fetchingAvailableResourcePools"
                                                :loading="fetchingAvailableResourcePools"
                                                v-model="resourcePoolIdLFS"
                                                item-text="long_id"
                                                item-value="rpid"
                                                label="Resource pool"
                                                outlined
                                                dense
                                                hide-details></v-select>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer />
                                            <v-btn
                                                v-if="managerResourcePools.length > 0"
                                                color="primary"
                                                text
                                                :disabled="loading || fetchingAvailableResourcePools"
                                                :loading="loading || fetchingAvailableResourcePools"
                                                @click="updateResourcePoolForLFS">
                                                save
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <the-secrets-listing :level="2" />
                </v-tab-item>
                <v-tab-item>
                    <the-space-LFS />
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
    </v-card>
</template>

<script>
import { dropboxEnums } from '@/mixins/dropbox'
import { mapGetters, mapState } from 'vuex'
import { enumsData } from '@/mixins/enums'
import eventBus from '@/components/EventBus'
import { intercomMethods } from '@/mixins/intercom'
import { fetchTask } from '@/apis'
import { usageAndResources } from '@/mixins/usageAndResources'

import OrgFeatureStatus from '@/components/OrgFeatureStatus.vue'

const TheSecretsListing = () => import('@/components/TheSecretsListing.vue')
const TheSpaceLFS = () => import('../components/TheSpaceLFS.vue')

const featureProperties = {
    loading: false,
    enabled: false,
    canEnable: false,
    disabledReason: '',
    error: '',
    fn: () => {}
}

export default {
    mixins: [dropboxEnums, enumsData, intercomMethods, usageAndResources],
    components: {
        TheSecretsListing,
        TheSpaceLFS,
        OrgFeatureStatus
    },
    data() {
        return {
            resourcePoolIdCredit: null,
            resourcePoolIdSubscription: null,
            resourcePoolIdLFS: null,
            resourcePoolId: null,
            resourcePoolName: '',
            error: false,
            loading: false,
            errorContent: 'AN ERROR HAS OCCURRED',
            switchingFeature: '',
            featureGroups: {
                HPC: 'Compute',
                EXTRAS: 'Additional features'
            },
            features: {
                vimeo: {
                    ...featureProperties,
                    label: 'Video Library',
                    description: 'Upload videos to make your content more interactive on Nuvolos.',
                    endpointProperty: 'vimeo_enabled',
                    group: 'EXTRAS'
                },
                tables: {
                    ...featureProperties,
                    label: 'Database Tables',
                    description: 'Use the Scientific Data Warehouse to work with massive amounts of tabular data.',
                    endpointProperty: 'has_tables',
                    cb: this.activateTable,
                    group: 'EXTRAS'
                },
                hpc: {
                    ...featureProperties,
                    label: 'High Performance Computing',
                    description: 'Scale your application to dedicated high-performance compute nodes.',
                    endpointProperty: 'beegfs_sync',
                    group: 'HPC'
                }
            },
            tab: 0
        }
    },
    async created() {
        this.checkDropboxStatus()

        eventBus.$on('global-dialog-accept', () => {
            if (this.tab !== 0) return
            if (this.switchingFeature) {
                this.enableFeature()
            } else {
                this.switchResourcePool()
            }
        })
        Object.keys(this.features).forEach(this.fetchStatusOfFeature)
    },
    beforeDestroy() {
        eventBus.$off('global-dialog-accept')
    },
    methods: {
        confirmFeatureSwitch(feature) {
            return async newState => {
                this.switchingFeature = feature
                const dialogTitle = `Enabling ${this.features[feature].label}`
                const dialogText = `Please note that this feature cannot be turned off in self-service, however you're free to release the resources in it anytime to control (potential) costs.`
                this.$store.dispatch('showGlobalDialog', {
                    dialogTitle,
                    dialogText,
                    dialogAction: ['cancel', 'confirm']
                })
            }
        },
        async enableFeature() {
            const feature = this.switchingFeature
            const { sid } = this.$route.params
            const f = this.features[feature]
            f.loading = true

            if (f.cb) return f.cb()

            try {
                const { data } = await this.$axios.patch(`spaces/${sid}`, {
                    [this.features[feature].endpointProperty]: !f.enabled
                })
                f.loading = false
                this.$store.commit('spaceStore/setCurrentSpace', data)
            } catch (error) {
                console.error(error)
                this.$store.dispatch('showSnackBar', {
                    snackBarText: `Failed to ${this.features[feature].enabled ? 'disable' : 'enable'} ${this.features[
                        feature
                    ].label.toLowerCase()}, please try again later!`,
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
                f.loading = false
                this.switchingFeature = ''
            }
        },
        fetchStatusOfFeature(feature) {
            const f = this.features[feature]
            f.loading = true
            f.fn = this.confirmFeatureSwitch(feature)
            this.$axios
                .get(`/spaces/${this.$route.params.sid}/can_enable_${feature}`)
                .then(({ data }) => {
                    if (this.currentSpace) f.enabled = this.currentSpace[f.endpointProperty]
                    f.canEnable = data[`can_enable_${feature}`]
                    f.disabledReason = data.reason
                    f.loading = false
                })
                .catch(error => {
                    f.loading = false
                    f.error = error.message
                })
        },
        updateResourcePoolForCredit() {
            this.resourcePoolId = this.resourcePoolIdCredit
            this.resourceTypes = [10, 20, 30, 50]
            this.updateResourcePool()
        },
        updateResourcePoolForSubscription() {
            this.resourcePoolId = this.resourcePoolIdSubscription
            this.resourceTypes = [0, 60, 70]
            this.updateResourcePool()
        },
        updateResourcePoolForLFS() {
            this.resourcePoolId = this.resourcePoolIdLFS
            this.resourceTypes = [90]
            this.updateResourcePool()
        },
        updateResourcePool() {
            this.$store.dispatch('showGlobalDialog', {
                dialogTitle: 'Remapping resources',
                dialogText: `Are you sure you want to remap your resources for this space to <b>${
                    this.availableResourcePools.find(rp => rp.rpid === this.resourcePoolId).long_id
                }</b>?`,
                dialogAction: ['accept', 'dismiss']
            })
        },
        switchResourcePool() {
            this.loading = true
            this.$axios
                .put(`/resource/${this.resourcePoolId}/map/space/${this.$route.params.sid}/rtypes`, {
                    rtypes: this.resourceTypes
                })
                .then(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Space updated successfully',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                    // Update the RP label to the new one
                    this.$store.dispatch('spaceStore/fetchMappedResources', this.$route.params.sid)
                })
                .catch(error => {
                    this.error = true
                    this.errorContent = error
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async activateTable() {
            this.$store.dispatch('showSnackBar', {
                snackBarText: 'Activating tables...',
                snackBarTimeout: 5000,
                snackBarIcon: 'info'
            })
            const { data } = await this.$axios.get(`/spaces/${this.$route.params.sid}/activate_async`)
            const taskResult = await fetchTask(data.tkid)
            if (taskResult instanceof Error) {
                this.features.tables.error = taskResult.message
            } else {
                this.features.tables.enabled = true
                this.features.tables.loading = false
                this.$store.dispatch('orgStore/fetchOrgSpaces', this.$route.params.oid)
            }
        },
        featuresByGroup(group) {
            const features = []
            for (const feature in this.features) {
                if (this.features[feature].group === group) features.push(this.features[feature])
            }
            return features
        }
    },
    computed: {
        ...mapState(['userInfo']),
        ...mapGetters('spaceStore', ['isSpaceAdmin', 'currentSpaceType', 'isSpaceArchived']),
        ...mapState('userStore', ['fetchingAvailableResourcePools', 'availableResourcePools', 'defaultResourcePool']),
        ...mapState('spaceStore', ['mappedResources', 'currentSpace']),
        getSpaceName() {
            return this.$store.getters['spaceStore/spaceLongNameById'](this.$route.params.sid)
        },
        getInstanceName() {
            return this.$store.getters['instanceStore/instanceLongNameById'](this.$route.params.iid)
        },
        getSpaceDescription() {
            return this.$store.getters['spaceStore/spaceDescriptionById'](this.$route.params.sid)
        },
        managerResourcePools() {
            // filter down the resrouce pools to the ones that we have manager roles to.
            return this.availableResourcePools.filter(rp => rp.is_rp_manager === true || rp.rpid === this.resourcePoolIdCredit)
        },
        activeResourceTypes() {
            return this.resourceTypes.filter(rtype => rtype.icon && rtype.enumKey)
        }
    },
    watch: {
        mappedResources: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.resourcePoolIdCredit = Number(Object.keys(newVal.HPC_INTER_COMPUTE)[0])
                    this.resourcePoolName = Object.values(newVal.HPC_INTER_COMPUTE)[0]
                    this.resourcePoolIdSubscription = Number(Object.keys(newVal.NORMAL_APP)[0])
                    this.resourcePoolIdLFS = Number(Object.keys(newVal.LFS)[0])
                }
            }
        },
        currentSpace: {
            immediate: true,
            handler(val) {
                this.features.vimeo.enabled = val.vimeo_enabled
                this.features.hpc.enabled = val.beegfs_sync
                this.features.tables.enabled = val.has_tables
            }
        }
    }
}
</script>
